import { Outlet, useLocation } from 'react-router-dom';

import { Aside, LayoutHeader } from '@src/widgets/';
import useRedirectToSavedPath from '@/hooks/redirect/useRedirectToSavedPath';
import { useAppState } from '@/lib/store/app';
import { useJivoRouting } from '@src/app/providers/JivoProvider/useJivoRouting';

import css from './css.module.scss';

interface AppLayoutProps {
  hasError?: boolean;
}

const AppLayout = ({ hasError }: AppLayoutProps) => {
  useRedirectToSavedPath();
  useJivoRouting();
  const { showLayoutHeader } = useAppState();

  return (
    <>
      <div className={css.container}>
        <Aside />
        <div className={css.content}>
          {showLayoutHeader && <LayoutHeader />}
          <div className={css.routes}>
            {hasError && (
              <div className={css.error}>
                Что то пошло не так, попробуйте перезагрузить страницу
              </div>
            )}
            <Outlet />
          </div>
        </div>
      </div>
    </>
  );
};

export default AppLayout;
