import type { NavigateProps } from 'react-router-dom';
import { Navigate } from 'react-router-dom';

interface RedirectProps extends Omit<NavigateProps, 'to'> {
  to: string;
  replace?: boolean;
}

const Redirect = ({ to, replace = true, ...props }: RedirectProps) => {
  return <Navigate to={to} replace={replace} {...props} />;
};

export default Redirect;
