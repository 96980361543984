import Api from './api'; // eslint-disable-line import/no-cycle

/**
 * Отправляет данные для входа в систему.
 *
 * @param {Object} params - Параметры для отправки данных.
 * @param {string} params.type - Тип входа (например, "email" или "phone").
 * @param {string} params.login - Логин для входа (мыло / телефон, в зависимости от типа входа).
 * @returns {Promise<{transport: string, expired: number, value: string, code: number}>} Промис, который разрешается с данными ответа от API.
 * @throws {Error} Если произошла ошибка при отправке данных.
 */

export async function sendLoginData({ type, login }) {
  const response = await Api.post('/auth/login', {
    type,
    [type]: login,
  });
  return response.data;
}

/**
 * Отправляет код для аутентификации.
 *
 * @param {string} type - Тип входа (например, "email" или "phone").
 * @param {string | null | undefined} requestData - Данные запроса (адрес электронной почты или номер телефона).
 * @param {number | null} code - Код для аутентификации.
 * @returns {Promise<{ access: string }>} Промис, который разрешается с объектом, содержащим заголовки и данные ответа.
 * @throws {Error} Если произошла ошибка при отправке кода.
 */
export async function sendCode(type, requestData, code) {
  const data = { type, [type]: requestData, code };
  const response = await Api.post('/auth/code', data, {
    withCredentials: true,
  });
  return response.data;
}

/** @returns {Promise<{ access: string }>} */
async function _refreshToken() {
  const response = await Api.post(
    '/auth/refresh',
    {},
    { withCredentials: true, _refresh: true },
  );
  return response.data;
}

/** @type {ReturnType<typeof refreshToken>} */
let refreshTokenInstance;
let refreshingToken = false;
export async function refreshToken() {
  if (!refreshingToken) {
    refreshingToken = true;
    refreshTokenInstance = _refreshToken();
  }
  return refreshTokenInstance.finally(() => (refreshingToken = false));
}

export async function logout() {
  const response = await Api.post(
    '/auth/logout',
    {},
    { withCredentials: true },
  );
  return response.data;
}

export default {};
