import { useQuery } from '@tanstack/react-query';

import type { ApiError } from '@/api/types';
import Api from '@/controllers/api';
import { ROUTES } from '@/constants/router.constants';
import { saveRedirectPath } from '@/hooks';

import type { TokenPayload, User } from './types';
import { authAllowedRoles } from './constants';

const onRedirect = () => {
  if (location.pathname !== `${ROUTES.INDEX}`) {
    saveRedirectPath();
    window.location.href = `${ROUTES.INDEX}`;
  }
};

export const useProfile = <Data = User>() => {
  return useQuery<User, ApiError, Data>({
    queryKey: ['user', 'profile'],
    queryFn: async ({ signal }) => {
      const access = localStorage.getItem('access');
      if (!access) {
        onRedirect();
        throw new Error('UNAUTHORIZED');
      }

      const decodedToken = atob(access.split('.')[1]);
      const payload = JSON.parse(decodedToken) as TokenPayload;

      if (!authAllowedRoles.includes(payload.role)) {
        onRedirect();
        throw new Error('UNAUTHORIZED');
      }

      const response = await Api.get<User>('/user/profile', { signal });

      return {
        ...response.data,
        isAuthorized: true,
      } satisfies User;
    },
  });
};
