import { message } from 'antd';
import axios from 'axios';

import saveRedirectPath from '@/hooks/redirect/saveRedirectPath';
import { useAppState } from '@/lib/store/app';

import { refreshToken } from './config'; // eslint-disable-line import/no-cycle
import { onLogout } from './auth';

const { REACT_APP_API_URL } = import.meta.env;

/**
 * The base URL for the API.
 * @type {string}
 */
export const baseURL = REACT_APP_API_URL;

const Api = axios.create({
  baseURL,
});

const setHeaders = (token, config) => {
  return {
    ...config,
    headers: {
      ...config.headers,
      'x-utc-offset': new Date().getTimezoneOffset(),
      ...(token ? { Authorization: `Bearer ${token}` } : {}),
    },
  };
};

const handleServerError = (errorResponse, canShowAxiosMessages) => {
  const errors = Array.isArray(errorResponse) ? errorResponse : [errorResponse];

  if (canShowAxiosMessages) {
    errors.forEach((error) => message.error(error));
  }
};

Api.interceptors.request.use(
  (config) => {
    const access = localStorage.getItem('access');
    return setHeaders(access, config);
  },
  (error) => Promise.reject(error),
);

Api.interceptors.response.use(
  (response) => response,
  async (error) => {
    const requestConfig = error.config;
    const status = error.response?.status || 500;

    const { canShowAxiosMessages } = useAppState.getState();

    if (status === 401 && !requestConfig._retry && !requestConfig._refresh) {
      requestConfig._retry = true;

      try {
        const { access } = await refreshToken();
        localStorage.setItem('access', access);
        const newConfig = setHeaders(access, requestConfig);
        return axios(newConfig);
      } catch (e) {
        saveRedirectPath();
        onLogout();
        return;
      }
    }

    const errorResponse =
      error.response?.data?.message || error.response?.data?.error;
    if (errorResponse) {
      handleServerError(errorResponse, canShowAxiosMessages);
    }

    throw error;
  },
);

export default Api;
