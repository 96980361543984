import { useMemo } from 'react';

import { useClients } from '@src/entities/dictionaries/queries';

import { hasTestClients } from './hasTestClients';

export const useHasClients = (profile?: boolean) => {
  const { data: clients } = useClients({
    options: {
      enabled: profile ?? true,
    },
  });

  const clientsIds = useMemo(() => {
    return clients?.map((client) => client?.id) || [];
  }, [clients]);

  const hasClients = useMemo(() => {
    return hasTestClients(clientsIds);
  }, [clientsIds]);

  return { hasClients, clientsIds };
};
