import { create } from 'zustand';

interface IAppState {
  isSelecting: boolean;
  setIsSelecting: (isSelecting: boolean) => void;
  isScrolling: boolean;
  setIsScrolling: (isSelecting: boolean) => void;

  canShowServerMessages: boolean;
  setCanShowServerMessages: (value: boolean) => void;

  showLayoutHeader: boolean;
  setShowLayoutHeader: (show: boolean) => void;
  showPageHeader: boolean;
  setShowPageHeader: (show: boolean) => void;
  showPageFooter: boolean;
  setShowPageFooter: (show: boolean) => void;

  isPullToRefreshActive: boolean;
  setIsPullToRefreshActive: (show: boolean) => void;

  setAllHeadersAndFooter: (show: boolean) => void;
}

export const useAppState = create<IAppState>((set) => ({
  isSelecting: false,
  setIsSelecting: (isSelecting) => set({ isSelecting }),
  isScrolling: false,
  setIsScrolling: (isScrolling) => set({ isScrolling }),

  canShowServerMessages: true,
  setCanShowServerMessages: (value) => set({ canShowServerMessages: value }),

  showLayoutHeader: true,
  setShowLayoutHeader: (show) => set({ showLayoutHeader: show }),
  showPageHeader: true,
  setShowPageHeader: (show) => set({ showPageHeader: show }),
  showPageFooter: true,
  setShowPageFooter: (show) => set({ showPageFooter: show }),

  isPullToRefreshActive: false,
  setIsPullToRefreshActive: (show) => set({ isPullToRefreshActive: show }),

  setAllHeadersAndFooter: (show) =>
    set(() => ({
      showLayoutHeader: show,
      showPageHeader: show,
      showPageFooter: show,
    })),
}));
