import CONSTANTS from '@mygigtechnologies/constants';

export const DOCUMENTS = new Map(Object.entries(CONSTANTS.DOCS));

//TODO возможно в entities уберу
export const SPEC_STATUSES = new Map([
  ['new', 'Новая'],
  ['doccheck', 'Требуются документы'],
  ['booked', 'Забронирована'],
  ['going', 'Исполнитель подтвердил бронирование'], // TODO: check on daily (в пути)
  ['delayed', 'Задерживается'],
  ['waiting', 'Ожидается'],
  ['checkingin', 'Подтвердите начало'],
  ['inprogress', 'Выполняется'],
  ['checkingout', 'Подтвердите завершение'],
  ['completed', 'Завершена'],
  ['confirmed', 'Подтверждена'],
  ['cancelled', 'Отменена'],
  ['toolate', 'Отменена из-за опоздания'],
  ['expired', 'Просрочена'],
  ['failed', 'Не выполнена'],
  ['repeat', 'Повторяется'],
  ['extended', 'Продлена'],
]);

export const REACT_APP_SUPPORT_PHONE =
  (import.meta.env.REACT_APP_SUPPORT_PHONE as string) || '8 800 333-03-29';

export const GROUPS = new Map([
  ['order', 'По заказам'],
  ['template', 'По шаблонам'],
  ['spec', 'По профессии'],
  ['employer', 'По управляющим'],
  ['workplace', 'По точкам'],
  ['region', 'По городам'],
]);

export const STATUSES = new Map([
  ['active', 'Активен'],
  ['inactive', 'Завершён'],
  ['deleted', 'Отменён'],
]);

export const PARTNER_STATUSES = new Map(
  Array.from(STATUSES).filter(([key]) => key !== 'deleted'),
);

export const STATUS_OPTIONS: {
  value: string;
  label: string;
}[] = Array.from(STATUSES).map(([value, label]) => ({
  value,
  label,
}));

export const JOB_STATUSES = new Map([
  ['booked', 'Забронирована'],
  ['doccheck', 'На проверке документов'],
  ['going', 'В пути'],
  ['delayed', 'Опаздывает'],
  ['waiting', 'Ожидается'],
  ['inprogress', 'Выполняется'],
  ['completed', 'Завершена'],
  ['confirmed', 'Подтверждена'],
  ['checkingin', 'Нет чекина в начале'],
  ['checkingout', 'Нет чекина в конце'],
  ['toolate', 'Отменена из-за опоздания'],
  ['cancelled', 'Отменена'],
  ['expired', 'Просрочена'],
  ['failed', 'Не выполнена'],
]);

export const JOB_STATUSES_OPTIONS: {
  value: string;
  label: string;
}[] = Array.from(JOB_STATUSES).map(([value, label]) => ({
  value,
  label,
}));

export const CONTRACT_TYPES = new Map([
  ['saas', 'Платформа'],
  ['services', 'Платформа +'],
  ['processing', 'Платформа PRO'],
]);

export const CONTRACT_TYPES_OPTIONS: {
  value: string;
  label: string;
}[] = Array.from(CONTRACT_TYPES).map(([value, label]) => ({
  value,
  label,
}));

export const PAYMENT_STATUSES = new Map([
  ['new', 'В ожидании'],
  ['synced', 'В обработке в 1C'],
  ['bank_init', 'Ожидание'],
  ['bank_processed', 'Проводится банком'],
  ['done', 'Проведён'],
  ['bank_done', 'Проведён банком'],
  ['rejected', 'Отклонён'],
  ['bank_rejected', 'Отклонён банком'],
]);

export const PAYMENT_STATUSES_OPTIONS: {
  value: string;
  label: string;
}[] = Array.from(PAYMENT_STATUSES).map(([value, label]) => ({
  value,
  label,
}));
