import type { TimeRangePickerProps } from 'antd';
import dayjs from 'dayjs';

dayjs.Ls.en.weekStart = 1; //начало недели в понедельник

type DynamicPreset = {
  label: string;
  getValue: () => [dayjs.Dayjs, dayjs.Dayjs];
};

export const DYNAMIC_PRESETS: DynamicPreset[] = [
  {
    label: 'Сегодня',
    getValue: () => [dayjs(), dayjs()],
  },
  {
    label: 'На прошлой неделе',
    getValue: () => [
      dayjs().startOf('week').subtract(1, 'week'),
      dayjs().endOf('week').subtract(1, 'week'),
    ],
  },
  {
    label: 'На этой неделе',
    getValue: () => [dayjs().startOf('week'), dayjs().endOf('week')],
  },
  {
    label: 'В этом месяце',
    getValue: () => [dayjs().startOf('month'), dayjs().endOf('month')],
  },
];

export const getDatePresets = (): TimeRangePickerProps['presets'] =>
  DYNAMIC_PRESETS.map((preset) => ({
    label: preset.label,
    value: preset.getValue(),
  }));
