import { Suspense } from 'react';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const RouterSuspense = (Component: React.ComponentType<any>) => (
  <Suspense fallback={<i />}>
    <Component />
  </Suspense>
);

export default RouterSuspense;
