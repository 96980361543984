import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { ROUTES } from '@/constants/router.constants';

import { useHasClients } from './useHasClients';

export const useJivoRouting = () => {
  const { pathname } = useLocation();
  const { hasClients } = useHasClients();

  const isOrderViewPage = pathname.includes(`/${ROUTES.ORDERS.VIEW}/`);
  const isBalanceDetailsPage = pathname.includes(`/${ROUTES.BALANCE.DETAILS}/`);

  useEffect(() => {
    if (!hasClients) {
      window.jivo_destroy();
      return;
    }

    if (isOrderViewPage || isBalanceDetailsPage) {
      window.jivo_destroy();
      return;
    }

    window.jivo_init();
  }, [hasClients, isOrderViewPage, isBalanceDetailsPage]);
};
