import { pickFields } from '@/utils';

import type {
  IOrdersResult,
  IPaymentsResult,
  IRegistriesSelectOption,
  IWorkersResult,
  OrdersPreviewFields,
  PaymentsPreviewFields,
  RegistriesField,
  RegistriesListFields,
  RegistriesStatuses,
  RegistriesType,
  RegistriesTypeData,
  WorkersPreviewFields,
} from './types';

export const registriesNames: { [K in RegistriesType]: string } = {
  workers: 'Исполнители',
  orders: 'Заказы',
  payments: 'Платежи',
};

export const registriesStatuses: Record<RegistriesStatuses, string> = {
  pending: 'В обработке...',
  success: 'Проведен',
  error: 'Ошибка',
  warning: 'Частично проведен',
};

export const registryStatusSelects: {
  value: RegistriesStatuses;
  label: string;
}[] = Object.entries(registriesStatuses).map(([value, label]) => ({
  value: value as RegistriesStatuses,
  label,
}));

export const registryTypeSelects: IRegistriesSelectOption[] = (
  Object.keys(registriesNames) as RegistriesType[]
).map((key) => ({
  label: registriesNames[key],
  value: key,
}));

type WorkersSchemaKeys = Exclude<WorkersPreviewFields, 'id'>;
type OrdersSchemaKeys = Exclude<OrdersPreviewFields, 'id'>;
type PaymentsSchemaKeys = Exclude<PaymentsPreviewFields, 'id'>;

const workersSchema: Record<WorkersSchemaKeys, boolean> = {
  inn: true,
  phone: true,
};

const ordersSchema: Record<OrdersSchemaKeys, boolean> = {
  workplaceId: true,
  customName: true,
  salaryPerJob: true,
  start: true,
  finish: true,
  startTime: true,
  finishTime: true,
  breakTime: true,
  salaryType: true,
  max: true,
  salaryPerUnit: true,
  unit: true,
  onlyForOwn: true,
  amount: true,
  comment: true,
  contractorId: false,
  clientId: false,
  employerId: false,
  coordinatorId: false,
  templateId: false,
  spec: false,
  paymentPerJob: false,
  phone: false,
  hour: false,
  type: false,
  min: false,
  paymentPerUnit: false,
  quantityFact: false,
  isManualConfirmOnly: false,
  isOperatorApprovalRequired: false,
  skipReceipt: false,
  isHidden: false,
  jobStatus: false,
  salaryMax: false,
  salaryMin: false,
  salaryPerHour: false,
  splitSum: false,
};

const paymentsSchema: Record<PaymentsSchemaKeys, boolean> = { ...ordersSchema };

export const registriesFieldsSchema: Record<
  RegistriesType,
  Record<string, boolean>
> = {
  workers: workersSchema,
  orders: ordersSchema,
  payments: paymentsSchema,
};

export const registryFields: Record<RegistriesType, RegistriesField[]> = {
  workers:
    pickFields<RegistriesTypeData<IWorkersResult>['workers']>(workersSchema),
  orders: pickFields<RegistriesTypeData<IOrdersResult>['orders']>(ordersSchema),
  payments:
    pickFields<RegistriesTypeData<IPaymentsResult>['payments']>(paymentsSchema),
};

export const allRegistryFields: Record<RegistriesType, RegistriesField[]> = {
  workers: pickFields<RegistriesTypeData<IWorkersResult>['workers']>(
    workersSchema,
    { includeAll: true },
  ),
  orders: pickFields<RegistriesTypeData<IOrdersResult>['orders']>(
    ordersSchema,
    { includeAll: true },
  ),
  payments: pickFields<RegistriesTypeData<IPaymentsResult>['payments']>(
    paymentsSchema,
    { includeAll: true },
  ),
};

export const registriesFieldsDescriptions: Record<
  RegistriesListFields,
  string
> = {
  id: '№',
  createdAt: 'Дата создания',
  status: 'Статус',
  company: 'Компания',
  type: 'Тип',
  statistics: 'Статистика',
  uploaded: 'Загрузил',
  download: 'Файл реестра',
};

export const registriesWorkersFieldsDescriptions: Record<
  WorkersPreviewFields,
  string
> = {
  id: '№ строки',
  phone: 'Телефон',
  inn: 'ИНН',
};

export const ordersFieldsDescriptions: Record<OrdersPreviewFields, string> = {
  id: '№ строки',
  workplaceId: 'ID точки',
  customName: 'Название профессии',
  start: 'Дата начала',
  finish: 'Дата окончания',
  startTime: 'Время начала',
  finishTime: 'Время окончания',
  breakTime: 'Перерыв',
  amount: 'Сколько нужно исполнителей',
  salaryPerJob: 'Сумма за смену',
  onlyForOwn: 'Только для своих',
  salaryType: 'Расчет оплаты',
  unit: 'Единица измерения',
  max: 'Макс сделка (кол-во)',
  salaryPerUnit: 'Ставка за шт',
  comment: 'Комментарий',
  // ---
  contractorId: 'id контрагента',
  clientId: 'id клиента',
  employerId: 'id заказчика',
  coordinatorId: 'id координатора',
  templateId: 'id шаблона',
  spec: 'Код профессии',
  paymentPerJob: 'Сумма за смену',
  phone: 'Телефон',
  hour: 'Часы',
  type: 'Тип смены',
  min: 'Мин сделка (кол-во)',
  paymentPerUnit: 'Ставка заказчика за шт',
  quantityFact: 'Факт сделка за смену',
  isManualConfirmOnly: 'Ручное подтверждение',
  isOperatorApprovalRequired: 'Подтверждение координатора',
  skipReceipt: 'Пропустить чек',
  isHidden: 'Публиковать',
  jobStatus: 'Статус смены',
  salaryMax: 'Максимальная сумма оплаты',
  salaryMin: 'Минимальная сумма оплаты',
  salaryPerHour: 'Ставка в час',
  splitSum: 'Разбивать сумму',
};

export const paymentsFieldsDescriptions: Record<PaymentsPreviewFields, string> =
  {
    ...ordersFieldsDescriptions,
  };
