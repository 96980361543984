import type { UseQueryOptions } from '@tanstack/react-query';
import { useQueries, useQuery } from '@tanstack/react-query';

import type { ApiError } from '@/api/types';
import Api from '@/controllers/api';
import type { IQueryRequest } from '@/utils/build-query-params';
import buildFullQueryParams from '@/utils/build-query-params';
import { uniqBy } from '@/utils/uniq-by';
import { pick } from '@/utils';

import type {
  ClientsQueryParams,
  PickClients,
  PointsResult,
  PointsResultQueryParams,
  ProfessionsResult,
} from './types';

export const useProfessions = <Data = ProfessionsResult>(
  options?: Omit<
    UseQueryOptions<ProfessionsResult, ApiError, Data>,
    'queryKey' | 'queryFn'
  >,
) =>
  useQuery<ProfessionsResult, ApiError, Data>({
    queryKey: ['professions', 'list'],
    queryFn: ({ signal }) =>
      Api.get<ProfessionsResult>(`/dictionaries/professions`, {
        signal,
      }).then(({ data }) => data),
    ...options,
  });

export const useSpecs = <Data = ISpecs>(
  options?: Omit<
    UseQueryOptions<ISpecs, ApiError, Data>,
    'queryKey' | 'queryFn'
  >,
) =>
  useQuery<ISpecs, ApiError, Data>({
    queryKey: ['specs', 'list'],
    queryFn: ({ signal }) =>
      Api.get<ISpecs>(`/dictionaries/specs`, {
        signal,
      }).then(({ data }) => data),
    ...options,
  });

export const useAdditionalWorkplaces = (workplaceIds: string[]) => {
  return useQueries({
    queries: workplaceIds.map((id) => ({
      queryKey: ['points', id],
      queryFn: () =>
        Api.get<IWorkplace>(`/workplaces?search=${id}`).then(
          ({ data }) => data,
        ),
    })),
  });
};

export const usePoints = <Data = PointsResult>({
  queryParams = { limit: 10000 },
  options,
}: {
  queryParams?: PointsResultQueryParams;
  options?: Omit<
    UseQueryOptions<PointsResult, ApiError, Data>,
    'queryKey' | 'queryFn'
  >;
}) => {
  return useQuery<PointsResult, ApiError, Data>({
    queryKey: ['points', 'list', queryParams],
    queryFn: ({ signal }) =>
      Api.get<IWorkplace[]>(
        buildFullQueryParams({
          path: '/workplaces',
          queryParams: queryParams as IQueryRequest,
        }),
        {
          signal,
        },
      ).then(({ data }: { data: IWorkplace[] }) => {
        const workplaces = data.map(
          ({ id, title, technicalName, ...workplace }) => ({
            id,
            title: technicalName ? `${technicalName}, ${title}` : title,
            ...workplace,
          }),
        );
        const regions = uniqBy(
          data.map(({ address }) => ({
            id: address.city,
            title: address.city,
          })),
          'id',
        );

        return { workplaces, regions };
      }) as unknown as PointsResult,
    ...options,
  });
};

export const useClients = <Data = PickClients>({
  queryParams = { limit: 10000 },
  options,
}: {
  queryParams?: ClientsQueryParams;
  options?: Omit<
    UseQueryOptions<PickClients, ApiError, Data>,
    'queryKey' | 'queryFn'
  >;
}) =>
  useQuery<PickClients, ApiError, Data>({
    queryKey: ['clients', 'list', queryParams],
    queryFn: ({ signal }) =>
      Api.get<PickClients>(
        buildFullQueryParams({
          path: '/clients',
          queryParams: queryParams as IQueryRequest,
        }),
        {
          signal,
        },
      ).then(({ data }: { data: PickClients }) =>
        data.map((item) => pick(item, ['id', 'title'])),
      ),
    ...options,
  });
