import type { ThemeConfig } from 'antd';
import { ConfigProvider } from 'antd';

import { getCssVariable } from '@/utils';

import type { ITable } from './table';
import Table from './table';
import css from './styles.module.scss';

interface IBaseTableProps extends ITable {
  customScrollY: string;
  scrollX?: string;
}

const theme: ThemeConfig = {
  token: {
    fontWeightStrong: 400,
  },
  components: {
    Table: {
      rowSelectedBg: getCssVariable('--cards-select-bg'),
    },
  },
};

const BaseTable = ({
  customScrollY,
  scrollX = '1300px',
  //------------------
  countSelected,
  hasSelected,
  RenderActions,
  //------------------
  ...props
}: IBaseTableProps) => {
  return (
    <ConfigProvider theme={theme}>
      {hasSelected && (
        <div className={css.tableHeader}>
          <div className={css.count}>Выбрано {countSelected}</div>
          <div className={css.actions}>{RenderActions}</div>
        </div>
      )}
      <Table
        scroll={{
          scrollToFirstRowOnChange: true,
          x: scrollX,
          y: customScrollY,
        }}
        pagination={false}
        tableLayout="fixed"
        {...props}
      />
    </ConfigProvider>
  );
};

export default BaseTable;
