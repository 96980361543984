function pickFields<T>(
  obj: Record<keyof T, boolean>,
  options?: { includeAll?: boolean },
) {
  const entries = Object.entries(obj) as [keyof T, boolean][];

  if (options?.includeAll) {
    return entries.map(([field]) => field);
  }

  return entries.filter(([, pass]) => pass).map(([field]) => field);
}
export default pickFields;
