import { useMemo } from 'react';
import { Image, Skeleton } from 'antd';
import { MaterialSymbol } from 'react-material-symbols';
import { useLocation } from 'react-router-dom';
import { Mask } from 'antd-mobile';

import { useProfile } from '@src/entities/user/queries';
import { useClients } from '@src/entities/dictionaries/queries';
import type { User, UserRole } from '@src/entities/user';
import { getFullName, getRole } from '@src/entities/user/lib/helpers';
import { useMediaState } from '@/hooks/media/useMediaState';
import { useBurgerState } from '@/hooks/media';
import { ROUTES } from '@/constants/router.constants';
import { Breadcrumbs } from '@src/features';

import css from './styles.module.scss';

const BASE_URL = import.meta.env.REACT_APP_API_URL;

function LayoutHeader() {
  const { data: user } = useProfile();
  const { data: clients, isFetching: clientsFetching } = useClients({});
  const { pathname } = useLocation();

  const fullName = useMemo(() => getFullName(user as User) || '', [user]);

  const role = useMemo(
    () => getRole(user?.role as UserRole) || '',
    [user?.role],
  );

  const { isOpen, toggleBurger } = useBurgerState();
  const { isMobile } = useMediaState();

  let isHideTopBorder;

  switch (true) {
    case pathname === `/${ROUTES.ORDERS.INDEX}/${ROUTES.ORDERS.LIST}`:
    case pathname.includes(`/${ROUTES.ORDERS.VIEW}/`):
    case pathname === `/${ROUTES.BALANCE.INDEX}/${ROUTES.BALANCE.LIST}`:
      isHideTopBorder = true;
      break;

    default:
      break;
  }

  const hideTopBorder =
    isHideTopBorder && isMobile ?
      {}
    : { borderBottom: '1px solid var(--layout-border)' };

  const isOrderEditPage =
    pathname.includes(
      `/${ROUTES.ORDERS.INDEX}/${ROUTES.ORDERS.LIST}/${ROUTES.ORDERS.EDIT}`,
    ) && isMobile;

  const isOrderNewPage =
    pathname.includes(`/${ROUTES.ORDERS.INDEX}/${ROUTES.ORDERS.NEW}`) &&
    isMobile;

  const hideLayoutHeader = isOrderEditPage || isOrderNewPage;

  return (
    <div
      className={hideLayoutHeader ? css.containerHide : css.container}
      style={hideTopBorder}
    >
      <Mask
        visible={isMobile && isOpen}
        onMaskClick={() => toggleBurger(false)}
      />
      <div
        className={css.burgerIcon}
        style={!isMobile ? { display: 'none' } : {}}
        onClick={() => toggleBurger()}
      >
        <MaterialSymbol
          icon="menu"
          size={24}
          fill
          grade={-25}
          color="var(--black)"
        />
      </div>
      <div
        className={css.breadcrumbs}
        style={isMobile ? { display: 'none' } : {}}
      >
        <Breadcrumbs />
      </div>
      <div className={css.meta}>
        <div className={css.account}>
          <span className={css.name}>{fullName}</span>
          <span className={css.role}>{role}</span>
        </div>
        {!clientsFetching ?
          <div className={css.avatars}>
            {clients && clients?.length > 5 ? null : (
              clients?.map(({ id, title }) => (
                <div key={id} className={css.avatar}>
                  <Image
                    src={`${BASE_URL}/clients/${id}/logo`}
                    alt={title}
                    width={24}
                    height={24}
                    preview={false}
                    placeholder={
                      <Skeleton.Avatar active={true} size={24} shape="circle" />
                    }
                  />
                </div>
              ))
            )}
          </div>
        : <Skeleton.Avatar active={true} size={24} shape="circle" />}
      </div>
    </div>
  );
}

export default LayoutHeader;
