import encode from 'jwt-encode';

const { REACT_APP_JIVO_SECRET } = import.meta.env;
/**
 * Генерирует JWT токен для Jivo на основе ID пользователя
 * @param userId - ID пользователя
 * @returns JWT токен
 */
const generateJivoToken = (userId: string | number): string => {
  const payload = {
    id: `${userId}`,
  };

  if (!REACT_APP_JIVO_SECRET) {
    return '';
  }

  return encode(payload, REACT_APP_JIVO_SECRET);
};

export default generateJivoToken;
