import { useRef, useState } from 'react';
import type { ChangeEvent } from 'react';
import axios from 'axios';
import type { AxiosError } from 'axios';
import { Form, Formik } from 'formik';
import type { FormikHelpers } from 'formik';
import {
  Form as AntForm,
  ConfigProvider,
  Input,
  Radio,
  Space,
  Typography,
} from 'antd';
import type { InputRef } from 'antd';

import { sendCode, sendLoginData } from '@/controllers/config';
import { onLogin } from '@/controllers/auth';
import { Button } from '@/ui';

import {
  codeValidationSchema,
  emailValidationSchema,
  phoneValidationSchema,
} from './login-validation-schema';
import useTimer from './use-timer'; // TODO: перенести в src/shared/hooks(FSD)
import phoneFormatter from './phone-formatter'; // TODO: перенести в src/shared/utils(FSD)
import css from './css.module.scss';

interface FormValues {
  login: string;
  code: number;
}

interface TSendLoginDataError {
  expired: number;
  error: string;
  message: string;
  statusCode: number;
}

const Login = () => {
  const [step, setStep] = useState<number>(1);
  const [method, setMethod] = useState<string>('email');
  const [isCodeLoading, setIsCodeLoading] = useState(false);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const { timer, resetTimer } = useTimer(0);

  const loginBtnRef = useRef<HTMLButtonElement>(null);
  const codeInputRef = useRef<InputRef>(null);

  const requestCode = async (login: string, onCode: (code: number) => void) => {
    try {
      setIsCodeLoading(true);
      const type = method;
      await sendLoginData({ type, login }).then(({ expired, code }) => {
        if (code) {
          onCode(code);
        }
        resetTimer(expired);
        setStep(2);
      });
    } catch (err: unknown) {
      if (!axios.isAxiosError(err)) return;
      const { response: res } = err as AxiosError<TSendLoginDataError>;
      if (res && res.status === 400) {
        const { expired } = res.data;
        resetTimer(expired);
        setStep(2);
      }
    } finally {
      setIsCodeLoading(false);
    }
  };

  const submitCode = async (
    values: FormValues,
    { resetForm }: FormikHelpers<FormValues>,
  ) => {
    try {
      setIsSubmitLoading(true);
      const { code, login } = values;
      await sendCode(method, login, code).then(async ({ access }) => {
        if (access) {
          await onLogin(access);
          resetForm?.();
        }
      });
    } catch (error) {
      console.error('Произошла ошибка при обработке данных:', error);
    } finally {
      setIsSubmitLoading(false);
    }
  };

  const variableValidationSchema =
    method === 'email' ? emailValidationSchema : phoneValidationSchema;

  const toggleMethod = (el: string) => {
    setMethod(el);
    setStep(1);
  };

  return (
    <div className={css.loginContainer}>
      <AntForm className={css.antForm}>
        <Typography className={css.formContainer}>
          <Formik<FormValues>
            initialValues={{} as FormValues}
            validateOnChange
            validateOnMount
            validationSchema={
              step === 1 ? variableValidationSchema : codeValidationSchema
            }
            onSubmit={submitCode}
          >
            {({
              isSubmitting,
              errors,
              values,
              resetForm,
              submitForm,
              validateForm,
              setFieldValue,
            }) => (
              <Form>
                <>
                  <div className={css.header}>
                    <div className={css.logo}>
                      <img src="/assets/images/logo.svg" alt="MyGig" />
                    </div>
                    <div className={css.buttonsBlock}>
                      <Radio.Group
                        buttonStyle="solid"
                        value={method}
                        onChange={(e) => {
                          toggleMethod(e.target.value as string);
                          resetForm();
                          setTimeout(validateForm, 0);
                        }}
                      >
                        <Radio.Button value="email">Почта</Radio.Button>
                        <Radio.Button value="phone">Телефон</Radio.Button>
                      </Radio.Group>
                    </div>
                  </div>
                  <AntForm.Item
                    help={
                      errors.login ?
                        <>{errors.login}</>
                      : <>
                          {`Код будет отправлен на ${
                            method === 'phone' ? 'телефон' : 'почту'
                          }`}
                        </>
                    }
                  >
                    <Input
                      maxLength={method === 'email' ? 256 : 18}
                      className={css.input}
                      type="text"
                      name="login"
                      value={
                        method === 'email' ?
                          values.login
                        : phoneFormatter(values.login)
                      }
                      onPressEnter={() => {
                        loginBtnRef.current?.click();
                      }}
                      onChange={
                        method === 'email' ?
                          (e) => setFieldValue('login', e.target.value)
                        : async (e: ChangeEvent<HTMLInputElement>) => {
                            const unformattedValue = e.target.value?.replaceAll(
                              /\D/g,
                              '',
                            );
                            const caret = e.target.selectionStart ?? 0;
                            const prior = e.target.value.slice(0, caret);
                            const properPrior = phoneFormatter(
                              prior.replace(/\D/g, ''),
                            );
                            const formatterOffset =
                              properPrior.length - prior.length;
                            const element = e.target;
                            window.requestAnimationFrame(() => {
                              element.selectionStart = caret + formatterOffset;
                              element.selectionEnd = caret + formatterOffset;
                            });
                            await setFieldValue('login', unformattedValue);
                          }
                      }
                      onPaste={(e: React.ClipboardEvent<HTMLInputElement>) => {
                        if (method === 'email') {
                          e.preventDefault();
                          const pastedText = e.clipboardData.getData('text');
                          const cleanedText = pastedText.replace(/\s/g, '');
                          void setFieldValue('login', cleanedText);
                          (e.target as HTMLInputElement).value = cleanedText;
                        }
                        if (method === 'phone') {
                          e.preventDefault();
                          const pastedText = e.clipboardData.getData('text');
                          let cleanedInput = pastedText.replace(/\D/g, '');

                          if (cleanedInput.length < 10) {
                            // Если номер меньше 10 чисел
                            void setFieldValue('login', '7' + cleanedInput);
                            (e.target as HTMLInputElement).value = cleanedInput;
                          } else if (cleanedInput.length === 10) {
                            // Если ровно 10 цифр, добавляем 7 в начало
                            cleanedInput = '7' + cleanedInput;
                            const formatted = phoneFormatter(cleanedInput);
                            void setFieldValue('login', cleanedInput);
                            (e.target as HTMLInputElement).value = formatted;
                          } else if (cleanedInput.length >= 11) {
                            // Если 11 или более цифр
                            if (cleanedInput[0] === '8') {
                              // Если начинается с 8, заменяем на 7
                              cleanedInput = '7' + cleanedInput.slice(1);
                            } else if (cleanedInput[0] !== '7') {
                              // Если не начинается с 7, обрезаем первую цифру
                              cleanedInput = '7' + cleanedInput.slice(1);
                            }
                            // Обрезаем до 11 цифр, если больше
                            cleanedInput = cleanedInput.slice(0, 11);
                            const formatted = phoneFormatter(cleanedInput);
                            void setFieldValue('login', cleanedInput);
                            (e.target as HTMLInputElement).value = formatted;
                          }
                        }
                      }}
                      placeholder={method === 'email' ? 'Почта' : 'Телефон'}
                      disabled={step === 2}
                    />
                  </AntForm.Item>
                  <>
                    <Space.Compact className={css.inputGroupStyles}>
                      <Button
                        color="white"
                        style={{ fontWeight: 400 }}
                        ref={loginBtnRef}
                        className={css.getCodeButton}
                        onClick={
                          timer === 0 ?
                            () => {
                              void requestCode(values.login, (code) =>
                                setFieldValue('code', code),
                              );
                              setTimeout(
                                () => codeInputRef.current?.focus(),
                                100,
                              );
                            }
                          : undefined
                        }
                        debounceLoading={isCodeLoading}
                        disabled={
                          isSubmitting ||
                          !values.login ||
                          !!errors.login ||
                          timer > 0 ||
                          isCodeLoading
                        }
                      >
                        {timer > 0 ? `${timer} сек` : 'Отправить'}
                      </Button>
                      <AntForm.Item
                        help={errors.code ?? ''}
                        style={{ flex: 1 }}
                      >
                        <Input
                          ref={codeInputRef}
                          className={css.input}
                          maxLength={4}
                          type="text"
                          name="code"
                          value={values.code || undefined}
                          onChange={(e) =>
                            setFieldValue(
                              'code',
                              Number(e.target.value?.replace(/\D/g, '')) ??
                                undefined,
                            )
                          }
                          disabled={step < 2}
                          placeholder="Введите полученный код"
                          onPressEnter={() => {
                            if (!values.code || !!errors.code) return;
                            void submitForm();
                          }}
                        />
                      </AntForm.Item>
                    </Space.Compact>
                    <Button
                      color={!values.code || !!errors.code ? 'white' : 'yellow'}
                      style={{ fontWeight: 400 }}
                      onClick={submitForm}
                      type="primary"
                      debounceLoading={isSubmitLoading}
                      className={css.submitButton}
                      disabled={
                        !values.code || !!errors.code || isSubmitLoading
                      }
                    >
                      Войти
                    </Button>
                  </>
                </>
              </Form>
            )}
          </Formik>
        </Typography>
      </AntForm>
    </div>
  );
};

//динамические переменные не работают для ANTD в пределах этой страницы
//как перенесем login в FSD - то стили подхватятся из ThemeProvider
const COLORS = {
  PRIMARY: '#F7CE17',
  CONTAINER_BG: '#FAFAFA',
  BORDER: '#d9d9d9',
  SPLIT_LINE: '#d9d9d9',
  PLACEHOLDER: '#bfbfbf',
  ERROR_TEXT: '#b72025',
};

export const AntTheme = {
  token: {
    fontFamily: 'Roboto, sans-serif',
    primaryColor: COLORS.PRIMARY,
    colorPrimary: COLORS.PRIMARY,

    borderRadius: parseInt('8px', 10),
    colorBgContainer: COLORS.CONTAINER_BG,
    colorBorder: COLORS.BORDER,
    colorSplit: COLORS.SPLIT_LINE,
    colorTextPlaceholder: COLORS.PLACEHOLDER,
    colorErrorText: COLORS.ERROR_TEXT,
  },
};

export default function LoginApp() {
  return (
    <ConfigProvider theme={AntTheme}>
      <Login />
    </ConfigProvider>
  );
}
