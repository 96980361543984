import { useEffect, useMemo } from 'react';

import { KEY_CODES } from '@/constants/keycodes.constants';

import { useBurgerState } from './useBurgerState';

export default function useBurger() {
  const { isOpen, toggleBurger, burgerWidth } = useBurgerState();

  useEffect(() => {
    const handleKey = (event: KeyboardEvent) => {
      if (event.target !== document.body) return;
      if (event.keyCode === KEY_CODES.BRACKET_CLOSING) {
        toggleBurger();
      }
    };
    window.addEventListener('keydown', handleKey);
    return () => {
      window.removeEventListener('keydown', handleKey);
    };
  }, [toggleBurger]);

  return useMemo(() => {
    return {
      isOpen,
      toggleBurger,
      burgerWidth,
    };
  }, [isOpen, toggleBurger, burgerWidth]);
}
