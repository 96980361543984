import { Table as AntTable, ConfigProvider } from 'antd';
import type { TableProps as AntTableProps, ThemeConfig } from 'antd';
import type { ConfigProviderProps } from 'antd/es/config-provider';
import type { ReactNode } from 'react';

export interface ITable extends AntTableProps {
  theme?: ConfigProviderProps['theme'];
  style?: React.CSSProperties;
  //for selection
  countSelected?: number;
  hasSelected?: boolean;
  RenderActions?: ReactNode;
}

const defaultTheme: ThemeConfig = {
  token: {
    colorPrimary: '#2f80ed', //var('--table-alt-primary')
    colorText: 'var(--app-text)',
  },
  components: {
    Table: {
      headerBorderRadius: 0,
      headerBg: 'var(--table-header-bg)',
      headerColor: 'var(--table-header-text)',
      borderColor: 'var(--table-border)',
      rowHoverBg: 'var(--table-row-hover)',
      cellPaddingBlock: 10,
    },
  },
};

function Table({ theme = defaultTheme, style = {}, ...props }: ITable) {
  return (
    <ConfigProvider theme={theme}>
      <AntTable style={style} {...props} />
    </ConfigProvider>
  );
}

export default Table;
