import { type ReactNode, useEffect, useState } from 'react';

import { useProfile } from '@src/entities/user/queries';

import generateJivoToken from './generateJivoToken';
import { useHasClients } from './useHasClients';

const { REACT_APP_JIVO_SECRET } = import.meta.env;

const JivoProvider = ({ children }: { children: ReactNode }) => {
  const [isJivoReady, setIsJivoReady] = useState(false);
  const { data: profile } = useProfile();

  const { hasClients, clientsIds } = useHasClients(!!profile);

  useEffect(() => {
    window.jivo_onLoadCallback = function () {
      setIsJivoReady(true);
    };

    return () => {
      window.jivo_onLoadCallback = function () {};
      setIsJivoReady(false);
    };
  }, [clientsIds]);

  useEffect(() => {
    if (!isJivoReady) return;

    if (!REACT_APP_JIVO_SECRET || !profile || !hasClients) {
      window.jivo_api?.clearHistory();
      window.jivo_destroy();
      return;
    }

    const { id, email, firstname, phone } = profile;
    window.jivo_init();
    window.jivo_api?.setUserToken(generateJivoToken(id));
    window.jivo_api?.setContactInfo({
      name: firstname,
      email,
      phone: `+${phone}`,
    });
  }, [hasClients, isJivoReady, profile]);

  return children;
};

export default JivoProvider;
