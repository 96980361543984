const testProjects = [
  '66bc74ebf536ae26100470dd',
  '6638f68430fb8cf2a4c912af',
  '66b483cbbe90904f2b513df5',
  '66b46eb6d188b292b5a1261e',
  '664cbf9f471443c986d4e4a2',
  '65780383ef0f5c00162810ba',
  '63a0b433dc9c6900079de635',
  '639759eac003fa000964b331',
];

export const hasTestClients = (userClients: string[]) => {
  return userClients.some((clientId) => testProjects.includes(clientId));
};
