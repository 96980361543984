export function uniqBy<T>(
  arr: T[],
  predicate: keyof T | ((item: T) => string),
): T[] {
  const cb =
    typeof predicate === 'function' ? predicate : (
      (o: T) => String(o[predicate])
    );
  return [
    ...arr
      .reduce((map: Map<string, T>, item: T) => {
        const key =
          item === null || item === undefined ? String(item) : cb(item);

        /* eslint-disable no-unused-expressions */
        /* TODO: что этот код делает? */
        map.has(key) || map.set(key, item);
        return map;
      }, new Map<string, T>())
      .values(),
  ];
}
